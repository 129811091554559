import StackTrace from 'stacktrace-js'
import { useListen } from '@voix/composables/useEventBus'
import { defineNuxtPlugin, useRequestURL } from '#imports'

declare global {
  interface Window {
    MothershipConfig: any
    MothershipJs: any
  }
}

interface Window {
  MothershipJs: MothershipJs
}

interface MothershipOptions {
  [option: string]: any
  mothershipUrl: string // For testing
  apiKey: string
  enabled: boolean
  minimumErrorLevel: string // critical, error, warn, info, debug,
  environment: string | null
  version: string | null
  customPayload: object
  allowedDomains: Array<string>
  disallowedDomains: Array<string>
  disableIPCapture: boolean
  captureUncaught: boolean
}

class MothershipJs {
  private defaultOptions: MothershipOptions = {
    mothershipUrl: 'https://mothership.app',
    apiKey: '',
    enabled: true,
    environment: null,
    version: null,
    minimumErrorLevel: 'debug',
    customPayload: {},
    allowedDomains: [],
    disallowedDomains: [],
    disableIPCapture: false,
    captureUncaught: true,
  }

  constructor(public options: MothershipOptions) {
    this.options = Object.assign({}, this.defaultOptions, this.options)
  }

  set apiKey(value: string) {
    this.options.apiKey = value
  }

  get apiKey(): string {
    return this.options.apiKey
  }

  set enabled(value: boolean) {
    this.options.enabled = value
  }

  get enabled(): boolean {
    return this.options.enabled
  }

  set minimumErrorLevel(value: string) {
    this.options.minimumErrorLevel = value
  }

  get minimumErrorLevel(): string {
    return this.options.minimumErrorLevel
  }

  set environment(value: string) {
    this.options.environment = value
  }

  get environment(): string | null {
    return this.options.environment
  }

  set version(value: string) {
    this.options.version = value
  }

  get version(): string | null {
    return this.options.version
  }

  set customPayload(value: object) {
    this.options.customPayload = value
  }

  get customPayload(): object {
    return this.options.customPayload
  }

  set allowedDomains(value: Array<string>) {
    this.options.allowedDomains = value
  }

  get allowedDomains(): Array<string> {
    return this.options.allowedDomains
  }

  set disallowedDomains(value: Array<string>) {
    this.options.allowedDomains = value
  }

  get disallowedDomains(): Array<string> {
    return this.options.disallowedDomains
  }

  set disableIPCapture(value: boolean) {
    this.options.disableIPCapture = value
  }

  get disableIPCapture(): boolean {
    return this.options.disableIPCapture
  }

  set captureUncaught(value: boolean) {
    this.options.captureUncaught = value
  }

  get captureUncaught(): boolean {
    return this.options.captureUncaught
  }

  /**
   * Checks to see if the error logger is enabled and if it is
   * sends along the error to [[sendLog]]
   *
   * @param msg       The message captured or passed to us manually
   * @param url       URL captured or passed to us manually
   * @param error     Error object with stack trace
   */
  error(
    msg: string,
    url: string | null = null,
    error: Error | null = null,
    type: string | null = null,
  ) {
    if (this.options.enabled) {
      if (type === null)
        type = 'error'

      if (url && this.checkLevel(type) && this.checkDomains(url)) {
        this.buildRequestObject(type, msg, url, error)
          .then((request) => {
            this.sendLog(request)
          })
          .catch((error) => {
            console.warn('Could not parse the stack trace', error)
          })
      }
    }
  }

  critical(msg: string, url: string | null = null, error: Error | null = null) {
    this.error(msg, url, error, 'critical')
  }

  warn(msg: string, url: string | null = null, error: Error | null = null) {
    this.error(msg, url, error, 'warn')
  }

  info(msg: string, url: string | null = null, error: Error | null = null) {
    this.error(msg, url, error, 'info')
  }

  debug(msg: string, url: string | null = null, error: Error | null = null) {
    this.error(msg, url, error, 'debug')
  }

  private checkLevel(type: string): boolean {
    if (this.options.minimumErrorLevel === 'debug') {
      return true
    }
    else if (this.options.minimumErrorLevel === 'info') {
      if (type !== 'debug')
        return true
    }
    else if (this.options.minimumErrorLevel === 'warn') {
      if (type !== 'debug' && type !== 'info')
        return true
    }
    else if (this.options.minimumErrorLevel === 'error') {
      if (type === 'error' || type === 'critical')
        return true
    }
    else if (this.options.minimumErrorLevel === 'critical') {
      if (type === 'critical')
        return true
    }

    return false
  }

  private checkDomains(url: string): boolean {
    if (this.options.allowedDomains.length > 0) {
      const domain = url
        .replace('http://', '')
        .replace('https://', '')
        .split(/[/?#]/)[0]
      if (!this.options.allowedDomains.includes(domain))
        return false
    }
    if (this.options.disallowedDomains.length > 0) {
      const domain = url
        .replace('http://', '')
        .replace('https://', '')
        .split(/[/?#]/)[0]
      if (this.options.disallowedDomains.includes(domain))
        return false
    }

    return true
  }

  /**
   * Builds the request object and returns it
   *
   * @param level     What level is the log?
   * @param msg       The message that is emitted from the browser
   * @param url       URL captured from the browser error event
   * @param error     Error object with stack trace
   */
  private buildRequestObject(
    level: string,
    msg: string | Event,
    url: string | null = null,
    error: Error | null = null,
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const theUrl: string = url !== null ? url : window.location.toString()
      if (error instanceof Error) {
        StackTrace.fromError(error)
          .then((stackFrame: Array<object>) => {
            resolve({
              custom: this.options.customPayload,
              disableIPCapture: this.options.disableIPCapture,
              environment: this.options.environment,
              level,
              message: msg,
              platform: navigator.userAgent,
              trace: {
                message: error.message,
                stack: stackFrame,
              },
              url: theUrl,
              version: this.options.version,
            })
          })
          .catch((error: Error) => {
            resolve({
              custom: this.options.customPayload,
              disableIPCapture: this.options.disableIPCapture,
              environment: this.options.environment,
              level,
              message: msg,
              platform: navigator.userAgent,
              trace: {
                message: error.message,
                stack: error.stack,
              },
              url: theUrl,
              version: this.options.version,
            })

            return error
          })
      }
      else {
        const stack = error.stack ? error.stack : []
        resolve({
          custom: this.options.customPayload,
          disableIPCapture: this.options.disableIPCapture,
          environment: this.options.environment,
          level,
          message: msg,
          platform: navigator.userAgent,
          trace: {
            message: msg,
            stack,
          },
          url: theUrl,
          version: this.options.version,
        })
      }
    })
  }

  /**
   * Handles any uncaught errors in the code and gets the request and
   * sends it along to [[sendLog]] if captureUncaught is on in the options
   *
   * @param msg       The message that is emitted from the browser
   * @param url       URL captured from the browser error event
   * @param error     Error object with stack trace
   */
  public async logError(
    level: string,
    msg: string | Event,
    url: string | null = null,
    error: Error | null = null,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (
        this.options.enabled
        && url
        && this.options.captureUncaught
        && this.checkLevel(level)
        && this.checkDomains(url)
      ) {
        this.buildRequestObject('error', msg, url, error)
          .then((request) => {
            this.sendLog(request)
            resolve()
          })
          .catch((error) => {
            console.warn('Could not parse the stack trace', error)
            reject(error)
          })
      }
      else {
        reject(new Error('Mothership did not process this error due to level or permissions'))
      }
    })
  }

  /**
   * Sends the log back to Mothership
   *
   * @param request     The request payload
   */
  private async sendLog(request: object): Promise<Response> {
    if (this.options.apiKey === '')
      console.warn('Mothership Error: Please set your apiKey')

    const response = await fetch(`${this.options.mothershipUrl}/api/v1/logs/js`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': `Bearer ${this.options.apiKey}`,
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(request),
    })

    return response
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const Mothership = new MothershipJs({
    mothershipUrl: 'https://mothership.app',
    apiKey: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiN2RmZjQ1ODZmYmQxNTcwNDM1YzM4NWVjZDg4ZmNmNDNjMDZiNDA2NmZhODY3MDkyY2Q4ZjIxMzY0NWNmMmU4ZjFhNDg3MTM5ODRmNTlmNzMiLCJpYXQiOjE2ODkzNDI5MzcuOTgwMTM5LCJuYmYiOjE2ODkzNDI5MzcuOTgwMTQ1LCJleHAiOjE3MjA5NjUzMzcuOTc1NDM4LCJzdWIiOiI3Iiwic2NvcGVzIjpbImxvZ3MiXX0.L5EdUx8vDy6yQAakx5-Bq_EvI5cBTwLx1O527aS96caK378mnnnHOSY33RaAhlV3zheJgBUbKE0pZoZEUnyxKroglImGK9Yj4j8mCPAmrk6m3ZsN5A8mNbwqdPVoPeLjKv_xzdwZJ2n7H162DIe0IbhPJsNsbxk3Hk-EmQkcuFs',
    enabled: true,
    environment: 'development',
    version: '3.0.0',
    minimumErrorLevel: 'debug',
    customPayload: {},
    allowedDomains: ['localhost:3000'],
    disallowedDomains: [],
    disableIPCapture: false,
    captureUncaught: true,
  })

  // Log Errors
  function attemptLogError(error: unknown) {
    if (error instanceof Error) {
      const url = useRequestURL()
      Mothership.logError('error', error.message, url.toString(), error)
      console.error(error)
    }
  }

  // Default vueapp error handler in case something is missed outside of the ErrorBoundaries
  nuxtApp.vueApp.config.errorHandler = (error: unknown) => {
    attemptLogError(error)
  }

  // Emitted from the error boundaries
  useListen('voix:error', (error: unknown) => attemptLogError(error))

  // Log Warnings
  // nuxtApp.vueApp.config.warnHandler = (warning) => {
  //   const url = useRequestURL()
  //   Mothership.logError('warn', warning, url.toString())
  // }

  return {
    provide: {
      Mothership,
    },
  }
})
